import { WindowProxy } from '@sm360/helpers'
import Cookies from 'js-cookie'
import React from 'react'

import { getShowroomQuoteAPIUrl, getQuoteDeskingPaymentOptions, getJwt } from '../../api/showroom360/showroom360.service'

const initialState = {
    isFetching: false,
    dealerPromotion: {},
    showroomQuote: {},
    paymentOptions: {},
    hubName: '',
    showroom360ConfigVdp: {
        summaryMetaConfiguration: {},
        summaryCTAConfigurations: [],
        summaryHighlightCardConfigurations: [],
        summaryVDPSectionsConfig: [],
        summaryVDPConfig: {},
    },
    vehicleTrim: {},
    calculatorWidget: '',
    tradeInWidget: {
        tradeInTopOfThePage: '',
        tradeInBelowPicture: '',
        tradeInBelowCTA: '',
        tradeInAtTheBottom: '',
    },
    dealerInfos: {},
    quoteId: '',
    XMSConfig: {
        taggingConfigurations: [],
        tripleMathBoxConfiguration: {},
    },
    vehiclePromo: {},
    articleVehicle: {},
    similarVehicles: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'fetching': {
            return {
                ...state,
                isFetching: true,
            }
        }
        case 'fetchSucceed': {
            return {
                ...state,
                isFetching: false,
            }
        }
        case 'updateState': {
            return {
                ...state,
                ...action.state,
            }
        }
        case 'updateShowroomQuote': {
            return {
                ...state,
                showroomQuote: action.showroomQuote,
            }
        }
        case 'updatePaymentOptions': {
            return {
                ...state,
                paymentOptions: action.paymentOptions,
            }
        }
        case 'updateVehicleTrim': {
            return {
                ...state,
                vehicleTrim: action.vehicleTrim,
            }
        }
        case 'init': {
            return {
                ...state,
                dealerPromotion: action.dealerPromotion,
                showroomQuote: action.showroomQuote,
                isFetching: true,
                hubName: action.hubName,
                showroom360ConfigVdp: action.showroom360ConfigVdp,
                vehicleTrim: action.vehicleTrim,
                calculatorWidget: action.calculatorWidget,
                tradeInWidget: action.tradeInWidget,
                dealerInfos: action.dealerInfos,
                quoteId: action.quoteId,
                domain: action.domain,
                paymentOptions: action.paymentOptions,
                XMSConfig: action.XMSConfig,
                vehiclePromo: action.vehiclePromo,
                articleVehicle: action.articleVehicle,
                similarVehicles: action.similarVehicles,
            }
        }
        default:
            return state
    }
}

const SummaryContext = React.createContext(initialState)

const SummaryProvider = ({ initialData, children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    if (!state.isFetching) {
        if (initialData) {
            dispatch({ type: 'init', ...initialData })
        }
    }

    React.useEffect(() => {
        fetchShowroomQuote()
    }, [state.quoteId])

    React.useEffect(() => {
        if (WindowProxy) {
            WindowProxy.document.addEventListener('sm360.widgets.garage.added', () => fetchShowroomQuote())
            WindowProxy.document.addEventListener('sm360.widgets.tradein.created', () => fetchShowroomQuote(4000))
            WindowProxy.document.addEventListener('sm360.calculator.vdp.modal.close', (e) => {
                if (e?.detail?.paymentOptions?.purchaseMethod !== 'cash') {
                    updateVehiclePurchaseMethod(e?.detail?.paymentOptions)
                    dispatch({ type: 'updatePaymentOptions', paymentOptions: e?.detail?.paymentOptions })
                } else {
                    const updatedPaymentOptions = {
                        paymentOptions: {
                            ...state.paymentOptions,
                            purchaseMethod: 'cash',
                            transactionToken: e?.detail?.paymentOptions?.transactionToken,
                        },
                    }
                    dispatch({ type: 'updatePaymentOptions', paymentOptions: updatedPaymentOptions.paymentOptions })
                }
            })
        }
    }, [])

    const updateVehiclePurchaseMethod = async (paymentOptionsObj) => {
        const updatedVehicleTrim = {
            ...state.vehicleTrim, // Spread the properties of vehicleTrim
            vehicleTrim: {
                ...state.vehicleTrim.vehicleTrim, // Spread the properties of the nested vehicleTrim
                paymentOptions: {
                    ...state.vehicleTrim.vehicleTrim.paymentOptions, // Spread the existing paymentOptions
                    [paymentOptionsObj?.purchaseMethod]: {
                        ...state.vehicleTrim.vehicleTrim.paymentOptions.finance, // Spread existing finance if it exists
                        ...paymentOptionsObj.finance, // Overwrite or add properties from the finance object
                    },
                },
            },
        }
        dispatch({ type: 'updateVehicleTrim', vehicleTrim: updatedVehicleTrim })
    }

    const fetchShowroomQuote = async (timeOut = 0) => {
        const cookie = Cookies.get('auth-token')
        if (cookie) {
            setTimeout(async function () {
                const showroomQuote = await getShowroomQuoteAPIUrl(state.quoteId, cookie, state.dealerInfos)
                if (showroomQuote) {
                    dispatch({ type: 'updateShowroomQuote', showroomQuote })
                }
                const { orgId, orgUnitId, orgUnitProvince, lang } = state.dealerInfos
                const paramsPaymentOptions = {
                    quoteId: state.quoteId || '',
                    lang,
                    location: orgUnitProvince,
                    orgId: parseInt(orgId),
                    orgUnitId: parseInt(orgUnitId),
                    payload: {
                        transactionToken: showroomQuote?.transactionToken,
                        saleType: showroomQuote?.saleType,
                        taxPlan: showroomQuote?.taxPlan,
                        vehicleCondition: showroomQuote?.vehicleCondition,
                        odometer: showroomQuote?.odometer,
                        purchaseMethod: showroomQuote?.purchaseMethod,
                        financePlan: showroomQuote?.financePlan,
                        kmPerYearPlan: showroomQuote?.kmPerYearPlan,
                        paymentFrequency: showroomQuote?.paymentFrequency,
                        term: showroomQuote?.term,
                        cashDown: showroomQuote?.cashDown,
                        tradeIn: showroomQuote?.tradeIn,
                        lien: showroomQuote?.lien,
                        financeOptions: showroomQuote?.financeOptions,
                    },
                    token: cookie,
                }

                const initPaymentOptions = await getQuoteDeskingPaymentOptions(paramsPaymentOptions)
                updateVehiclePurchaseMethod(initPaymentOptions)
                dispatch({ type: 'updatePaymentOptions', paymentOptions: initPaymentOptions })
            }, timeOut)
        }
    }

    return <SummaryContext.Provider value={{ state, dispatch }}>{children}</SummaryContext.Provider>
}

SummaryProvider.propTypes = {}

SummaryProvider.defaultProps = {}

export { SummaryContext, SummaryProvider }
